
<!-- <script setup>

import CryptoJS from 'crypto-js'
import { collect as cr_collect, message as cr_message } from '../constants/msg.js'


const collect_bytes = CryptoJS.AES.decrypt(cr_collect, 'secret-key-123');
const collect = JSON.parse(collect_bytes.toString(CryptoJS.enc.Utf8));

const message_bytes = CryptoJS.AES.decrypt(cr_message, 'secret-key-123');
const messages = JSON.parse(message_bytes.toString(CryptoJS.enc.Utf8));

const handleClick = (item) => {
  window.open(item.url, '_blank');
}
</script> -->
<script>
import CryptoJS from 'crypto-js'
import { collect as cr_collect, message as cr_message } from '../constants/msg.js'

export default {
  data() {
    return {
      messages: '',
      collect: ''
    }
  },
  mounted() {
    const collect_bytes = CryptoJS.AES.decrypt(cr_collect, 'secret-key-123');
    this.collect = JSON.parse(collect_bytes.toString(CryptoJS.enc.Utf8));

    const message_bytes = CryptoJS.AES.decrypt(cr_message, 'secret-key-123');
    this.messages = JSON.parse(message_bytes.toString(CryptoJS.enc.Utf8));

  },
  methods: {
    handleClick(item) {
      window.open(item.url, '_blank');
    }
  }
}
</script>
<template>
  <div class="fix-nav">
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span @click="handleClick(collect)">{{ collect.title }}</span>
        </div>
      </template>
      <div v-for="(msg, index) in messages" :key="index" class="text item">
        <p @click="handleClick(msg)">
          {{ msg.title }}
        </p>
      </div>
    </el-card>
  </div>
</template>

<style scoped> .fix-nav {
   position: fixed;
   top: 30%;
   left: 0;
   right: 0;
   /* transform: translate(50%); */
   background-color: #fff;
   z-index: 1000;
 }

 .box-card {
   background-color: #e3dcdc;
   border: 6px #ab4d4d solid;
   border-radius: 5px;
   max-height: 400px;
   overflow: auto;
 }

 .card-header {
   color: #c80034;
   font-weight: bold;
   text-align: center;
   font-size: 32px;
   cursor: pointer;
 }

 .text.item {
   text-align: center;
   border-radius: 5px;
   background-color: #ab4d4d;
   cursor: pointer;

 }

 .text p {
   line-height: 2.55555556;
   color: #fff;
   font-size: 18px;
   font-weight: bold
 }

 @media (max-width: 762px) {
   .card-header {
     font-size: 24px;
   }

   .text p {
     line-height: 2.4;
     font-size: 18px;
   }
 }
</style>