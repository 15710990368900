<template>
  <el-card :body-style="{ padding: '0px' }" @click="handleClick">
    <img :src="getImageUrl(cardData.imgNum)" class="image" />
    <div style="padding: 14px">
      <span class="image-title">{{ cardData.title }}</span>
      <div class="bottom">
        <p class="desc">
          {{ cardData.desc }}
        </p>
        <span class="date">{{ cardData.date }}</span>
      </div>
    </div>
  </el-card>
</template>


<!-- 
<script lang="ts" setup>
import { getImageUrl } from '../constants/news'

type CardItem = {
  imgNum: number
  title: string
  desc: string
  date: string
  url: string
}
const props = defineProps<{
  cardData: CardItem
}>()

const getUrl = (num) => {
  return `../assets/${num}.jpg`
}

const handleClick = () => {
}
</script> -->


<script>
import { getImageUrl } from '../constants/news'
export default {
  props: ['cardData'],
  data() {
    return {
      getImageUrl
    }
  },
  methods: {
    handleClick() {
      window.open(this.cardData.url, '_blank')
    }
  }
}
</script>
<style>
.el-card {
  cursor: pointer;
}

.time {
  font-size: 12px;
  color: #999;
}

.image-title:hover {
  color: #d82019;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
}

.button {
  padding: 0;
  min-height: auto;
}

.image {
  width: 100%;
  display: block;
}

.bottom .desc {
  color: #b2b2b2;
  font-size: 13px;
  display: -webkit-box;
  height: 72px;
  line-height: 24px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.bottom .date {
  display: block;
  color: #999999;
  font-family: Montserrat;
}
</style>