export const newsData = [
  {
    imgNum: 1,
    title: "内容运营优化 六个细节关乎成败",
    desc: "网站想获得好的排名，并不是必须用外链才可以(很多新手站长根本没有外链资源，实在这也无须过分担心)。外链只是排名的一个因素，占比并没有我们想象的那么高，关键词 谈到关键词，应该是企业网站的优化核心，和其他关键词比较，企业网站的关键词有时候是选择的，因为作为行业来说，企业在某些方面是独一无二的，用这些专属",
    date: "2023-12-08",
    url: "http://news.sohu.com/?spm=smpc.content.nav.2.1695390605895jox1vOq",
  },
  {
    imgNum: 2,
    title: "企业网站建设对搜索优化的几点建议",
    desc: "11月21日，由中铁二十一局集团公司承建的玉（溪）磨（憨）铁路站前10标段隧道正洞累计掘进达10070.7米，顺利实现隧道掘进突破10公里大关。玉磨铁路是中老国际铁路的重要组成部分，国家“一带一路”战略中的重要工程，亦是云南省在建的较大基础设施项目，建设好玉磨铁路使命光荣，责任重大。",
    date: "2023-12-25",
    url: "https://news.sohu.com/?scm=10000.260_14-201000.0.0.10005&spm=smpc.channel_258.block1_78_KJZNhZ_1_nav.2.16953906096320WXVNDJ_1090",
  },
  {
    imgNum: 3,
    title: "液体比重天平厂家谈液体密度测量外在因",
    desc: "网站想获得好的排名，并不是必须用外链才可以(很多新手站长根本没有外链资源，实在这也无须过分担心)。外链只是排名的一个因素，占比并没有我们想象的那么高，关键词 谈到关键词，应该是企业网站的优化核心，和其他关键词比较，企业网站的关键词有时候是选择的，因为作为行业来说，企业在某些方面是独一无二的，用这些专属",
    date: "2023-12-08",
    url: "https://auto.sohu.com/?scm=10000.260_14-201000.0.0.10005&spm=smpc.channel_258.block1_78_KJZNhZ_1_nav.4.1695390635263vm7FpfR_1090",
  },
  {
    imgNum: 4,
    title: "网站换域名需要注意的事项",
    desc: "11月21日，由中铁二十一局集团公司承建的玉（溪）磨（憨）铁路站前10标段隧道正洞累计掘进达10070.7米，顺利实现隧道掘进突破10公里大关。玉磨铁路是中老国际铁路的重要组成部分，国家“一带一路”战略中的重要工程，亦是云南省在建的较大基础设施项目，建设好玉磨铁路使命光荣，责任重大。",
    date: "2023-12-01",
    url: "https://cd.focus.cn/?useIp=true",
  },
  {
    imgNum: 5,
    title: "不锈钢储能罐用途有哪些",
    desc: "网站想获得好的排名，并不是必须用外链才可以(很多新手站长根本没有外链资源，实在这也无须过分担心)。外链只是排名的一个因素，占比并没有我们想象的那么高，关键词 谈到关键词，应该是企业网站的优化核心，和其他关键词比较，企业网站的关键词有时候是选择的，因为作为行业来说，企业在某些方面是独一无二的，用这些专属",
    date: "2023-12-08",
    url: "https://zhibo.focus.cn/cd/",
  },
  {
    imgNum: 6,
    title: "企业网站建设对搜索优化的几点建议",
    desc: "11月21日，由中铁二十一局集团公司承建的玉（溪）磨（憨）铁路站前10标段隧道正洞累计掘进达10070.7米，顺利实现隧道掘进突破10公里大关。玉磨铁路是中老国际铁路的重要组成部分，国家“一带一路”战略中的重要工程，亦是云南省在建的较大基础设施项目，建设好玉磨铁路使命光荣，责任重大。",
    date: "2023-12-05",
    url: "https://baike.focus.cn/",
  },
  {
    imgNum: 7,
    title: "恒温恒湿精密空调过滤网的性能优势",
    desc: "网站想获得好的排名，并不是必须用外链才可以(很多新手站长根本没有外链资源，实在这也无须过分担心)。外链只是排名的一个因素，占比并没有我们想象的那么高，关键词 谈到关键词，应该是企业网站的优化核心，和其他关键词比较，企业网站的关键词有时候是选择的，因为作为行业来说，企业在某些方面是独一无二的，用这些专属",
    date: "2023-12-07",
    url: "https://baike.focus.cn/",
  },
  {
    imgNum: 8,
    title: "硬质合金密度测试仪与硬质合金的密度性",
    desc: "11月21日，由中铁二十一局集团公司承建的玉（溪）磨（憨）铁路站前10标段隧道正洞累计掘进达10070.7米，顺利实现隧道掘进突破10公里大关。玉磨铁路是中老国际铁路的重要组成部分，国家“一带一路”战略中的重要工程，亦是云南省在建的较大基础设施项目，建设好玉磨铁路使命光荣，责任重大。",
    date: "2023-12-15",
    url: "https://house.focus.cn/author/157860400/newslist",
  },
  {
    imgNum: 9,
    title: "试样表面气泡对塑料颗粒密度测试仪测量",
    desc: "网站想获得好的排名，并不是必须用外链才可以(很多新手站长根本没有外链资源，实在这也无须过分担心)。外链只是排名的一个因素，占比并没有我们想象的那么高，关键词 谈到关键词，应该是企业网站的优化核心，和其他关键词比较，企业网站的关键词有时候是选择的，因为作为行业来说，企业在某些方面是独一无二的，用这些专属",
    date: "2023-12-08",
    url: "https://baike.focus.cn/",
  },
];

export function getImageUrl(name) {
  // return new URL(`../assets/${name}.jpg`, import.meta.url).href;

  return require(`../assets/${name}.jpg`);
}
