<template>
  <el-container>
    <el-header>
      <div class="pagebanner">
        <img src="../assets/banner.jpg" alt="">
      </div>
    </el-header>


    <el-main>
      <!-- 面包屑 -->
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>
            <i class="el-icon-house" style="width: 1em; height: 1em; margin-right: 4px"></i>
            主页
          </el-breadcrumb-item>
          <el-breadcrumb-item>新闻资讯</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <el-divider class="divider-1" />

      <!-- 新闻资讯标题 -->
      <div class="commonweb">
        <div class="clumb-title">
          <h3 class="cn font22">新闻资讯</h3>
          <h2 class="en font24">NEWS CENTER</h2>
        </div>

      </div>

      <!-- 图片新闻 -->
      <div class="image-news">
        <el-row :gutter="20">
          <template v-for="(item, index) in news">
            <el-col :xs="24" :span="8" :key="index">
              <ImageCard :card-data="item" />
            </el-col>
          </template>

        </el-row>
      </div>

      <!-- 分页 -->
      <div class="pagination">
        <el-pagination :page-size="10" @current-change="onCurrentChange" background layout="prev, pager, next"
          :total="30">
        </el-pagination>
      </div>


      <el-divider style="border-color:#d82019" />

      <div class="footer">
        <p>Copyright © 2023 版权所有</p>
      </div>

    </el-main>

  </el-container>
</template>


<script>
import ImageCard from './ImageCard.vue'
import { newsData } from '../constants/news'
const random = () => Math.floor(Math.random() * 9) + 0
export default {
  components: {
    ImageCard
  },
  data() {
    return {
      news: ""
    }
  },
  mounted() {
    this.news = JSON.parse(JSON.stringify(newsData))
  },
  methods: {
    onCurrentChange() {
      const data = JSON.parse(JSON.stringify(newsData))
      this.news.forEach((item) => {
        item.imgNum = data[random()].imgNum
        item.title = data[random()].title
        item.desc = data[random()].desc
        item.date = data[random()].date
      })
    }
  }
}
</script>
<style scoped lang="scss">  .pagebanner img {
    width: 100%;
    max-height: 300px;
  }

  .el-header {
    height: 60px;
    padding-left: 0;
    padding-right: 0;
  }

  :deep(.house-icon i) {
    display: none !important;
  }

  .clumb-title .en {
    color: #d82019;
    text-transform: uppercase;
    font-family: Rubik;
  }

  .clumb-title .cn {
    color: #34364b;
    padding: 0 0 4px 0;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ::v-deep.el-pagination.is-background .el-pager li.active {
    background-color: #666666 !important;
  }

  .footer {
    font-size: 13px;
    color: #333;
    text-align: center;
  }

  @media(min-width:1260px) {
    .pagebanner img {
      min-height: 130px;
    }

    .el-header {
      height: 170px !important;
      margin-top: 100px;
    }

    .breadcrumb {
      max-width: 1260px;
      margin: 0 auto;
    }

    .el-breadcrumb {
      float: right;
    }

    .pagebanner img {
      min-height: 60px;
    }

    .commonweb {
      padding-top: 77px;
    }

    .clumb-title,
    .image-news {
      max-width: 1260px;
      margin: 0 auto;
    }

    .image-news {
      padding-top: 60px;
      padding-bottom: 20px;
    }

    .image-news .el-col {
      margin-bottom: 30px;
    }

    .pagination {
      margin-top: 100px;

    }

  }

  @media (max-width: 762px) {
    .clumb-title .cn {
      padding: 0 0 7px 0;
    }

    .pagebanner img {
      min-height: 60px;
    }

    .divider-1.el-divider--horizontal {
      margin-top: 5px;
    }

    .commonweb {
      padding-bottom: 30px;
    }

    .image-news .el-col {
      margin-bottom: 15px;
      max-height: 400px;
      overflow: hidden;
    }

    /* .el-col .el-card {
      height: 390px;
    } */

    .el-main {
      /* height: 4140px; */
      padding-bottom: 70px;
    }

    /* .pagination {
      margin-top: 60px;
    } */
  }
</style>
