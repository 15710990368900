<template>
  <div>
    <NavAffix />
    <MsgAffix />
    <Home />
  </div>
</template>

<script>
import Home from './components/Home.vue'
import NavAffix from './components/NavAffix.vue';
import MsgAffix from './components/MsgAffix.vue'
export default {
  components: {
    Home,
    MsgAffix,
    NavAffix
  }
}
</script>

<style lang="scss" scoped></style>
